import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout.js"

const UpdateLogPage = () => {
  return (
    <Layout title="Update Log">
        <div className="text-wrapper">
            <h2>December 29, 2022</h2>
            <p>
              Added initial entry for <Link to="../deathgod-typei">Death God, type I</Link>.
            </p>
            <h2>July 12, 2022</h2>
            <p>
              Updated the <Link to="../bookburner">Book-Burner's entry</Link> with an inked version of her introductory portrait.
            </p>
            <h2>July 10, 2022</h2>
            <p>
              Added a new piece-- <Link to="../personalgrowth">Personal Growth After Dinner Parties</Link>-- and updated the styling of the website a bit so it's cleaner.
            </p>
            <h2>June 30, 2022</h2>
            <p>
                I've added the first few pieces to the project; some of the linked pictures are still in progress, but I hope to continue updating pretty regularly over the coming few months-- I have initial sketches for about 40 pieces so far and I really need to get caught up on them!
            </p>
        </div>
    </Layout>
  )
}

export default UpdateLogPage